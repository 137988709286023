<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div v-if="isEditMode">
      <h2 class="my-4 text-4xl font-semibold">Edit Email Template</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit Email Template</p>
      </div>
    </div>

    <div v-else>
      <h2 class="my-4 text-4xl font-semibold">Add Email Template</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Add an email template to the database</p>
      </div>
    </div>

    <!-- Email Content -->
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full text-sm font-semibold">ID</label>
        <input
          v-model="form.id"
          class="lb-admin-form-input rounded"
          placeholder="ID"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full text-sm font-semibold">From</label>
        <input
          v-model="form.from"
          class="lb-admin-form-input rounded"
          placeholder="From"
          type="email"
        />
      </div>
      <div class="w-full mt-5 lg:mr-5">
        <label class="w-full text-sm font-semibold">To</label>
        <input
          v-model="form.to"
          class="w-full lb-admin-form-input rounded"
          placeholder="To (separate multiple by comma)"
          type="text"
        />
      </div>
      <div class="w-full mt-5">
        <label class="w-full text-sm font-semibold">Cc</label>
        <input
          v-model="form.cc"
          class="w-full mr-5 lb-admin-form-input rounded"
          placeholder="Cc (separate multiple by comma)"
          type="text"
        />
      </div>
      <div class="w-full mt-5 lg:mr-5">
        <label class="w-full text-sm font-semibold">Bcc</label>
        <input
          v-model="form.bcc"
          class="w-full lb-admin-form-input rounded"
          placeholder="Bcc (separate multiple by comma)"
          type="text"
        />
      </div>
      <div class="w-full mt-5 lg:mr-5">
        <label class="w-full text-sm font-semibold">Subject line</label>
        <input
          v-model="form.subject"
          class="w-full lb-admin-form-input rounded"
          placeholder="Subject"
          type="text"
        />
      </div>

      <div class="w-full mt-5">
        <label class="w-full text-sm font-semibold">Body</label>
        <BaseEditor v-model="form.body" />

        <p class="text-italic text-xs mt-3">
          Placeholders available: [[firstname]], [[lastname]], [[unitname]],
          [[email]]
        </p>
      </div>
    </div>
    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        v-if="!isEditMode"
        @click.prevent="addTemplate"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Add Email Template
      </button>

      <button
        v-if="isEditMode"
        @click.prevent="updateTemplate(templateId)"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Update Email Template
      </button>

      <button
        v-if="isEditMode && hasAccess('email-templates', 'delete')"
        @click.prevent="deleteTemplate(templateId)"
        class="bg-red-600 ml-5 px-4 py-2 text-white font-semibold rounded"
      >
        Delete Email Template
      </button>
    </div>

    <hr class="mt-5" />

    <!-- Test Email Content -->
    <h2 class="font-bold text-2xl mt-3">Send Test Email</h2>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 lg:mr-5">
        <label class="w-full text-sm font-semibold">To</label>
        <input
          v-model="testform.to"
          class="w-full lb-admin-form-input rounded"
          placeholder="To (separate multiple by comma)"
          type="text"
        />
      </div>
    </div>

    <div class="w-full mt-10 pt-5">
      <button
        v-if="isEditMode"
        @click.prevent="sendTestMail()"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Send Test Mail
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import { mapGetters } from "vuex";
import { emailTemplateCollection } from "@/firebase.js";
import permissionsMixin from "@/mixins/permissions";
import BaseEditor from "../../components/admin/BaseEditor";
import { SENDTESTMAIL_URL } from "../../Constants";

import isNil from "lodash/isNil";

export default {
  name: "AdminEmailTemplate",
  mixins: [permissionsMixin],
  components: {
    BaseEditor,
  },
  data: () => ({
    templateId: null,
    testform: {
      to: "",
    },
    form: {
      id: "",
      from: "",
      to: "",
      cc: "",
      bcc: "",
      subject: "",
      body: "",
    },
    sendTestMailUrl: SENDTESTMAIL_URL,
  }),
  methods: {
    sendTestMail() {
      axios
        .post(this.sendTestMailUrl, {
          templateId: this.form.id,
          to: this.testform.to,
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Test email sent",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error Sending Test Mail",
            text: error.message,
          });
        });
    },
    getTemplate(templateId) {
      emailTemplateCollection
        .doc(templateId)
        .get()
        .then((doc) => {
          this.form.from = doc.data().from;
          this.form.to = doc.data().to;
          this.form.cc = doc.data().cc;
          this.form.bcc = doc.data().bcc;
          this.form.subject = doc.data().subject;
          this.form.body = doc.data().body;
          this.form.id = doc.id;
          this.templateId = doc.id;
        });
    },
    addTemplate() {
      const data = {
        from: this.form.from || null,
        to: this.form.to || null,
        cc: this.form.cc || null,
        bcc: this.form.bcc || null,
        subject: this.form.subject || null,
        body: this.form.body || null,
        createdby: this.user.data.uid,
        created: firebase.firestore.Timestamp.now(),
      };
      emailTemplateCollection
        .doc(this.form.id)
        .set(data)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Email Template Added",
          });
          this.$router.replace({ name: "admin-email-templates" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error adding Email Template",
            text: error.message,
          });
          this.$router.replace({ name: "admin-email-templates" });
        });
    },
    updateTemplate(templateId) {
      const data = {
        from: this.form.from || null,
        to: this.form.to || null,
        cc: this.form.cc || null,
        bcc: this.form.bcc || null,
        subject: this.form.subject || null,
        body: this.form.body || null,
        modifiedby: this.user.data.uid,
        modified: firebase.firestore.Timestamp.now(),
      };
      emailTemplateCollection
        .doc(templateId)
        .update(data)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Email template Updated",
          });
          this.$router.replace({ name: "admin-email-templates" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error updating Email template",
            text: error.message,
          });
          this.$router.replace({ name: "admin-email-templates" });
        });
    },
    deleteTemplate(templateId) {
      var userConfirmedDelete = confirm(
        "Are you sure you want to delete this email template?"
      );
      if (userConfirmedDelete) {
        emailTemplateCollection
          .doc(templateId)
          .delete()
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Email template Deleted",
            });

            this.$router.replace({ name: "admin-email-templates" });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: error,
            });
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isEditMode() {
      if (!isNil(this.$route.params.id)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (!isNil(this.$route.params.id)) {
      const templateId = this.$route.params.id;
      this.getTemplate(templateId);
    }
  },
};
</script>
